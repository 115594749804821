/** ====================
 *  Grid
=======================*/
.pt_0{
    padding-top: 0;
}
.pt_100 {
    padding-top: 100px;
}
.pb_100 {
    padding-bottom: 100px;
}
.pt_50 {
    padding-top: 50px;
}
.pt_40 {
    padding-top: 40px;
}
.pt_30 {
    padding-top: 30px;
}
.pt_20 {
    padding-top: 20px;
}
.pt_10 {
    padding-top: 10px;
}
.pb_50 {
    padding-bottom: 50px;
}
.mt_60 {
    margin-top: 60px;
}
.mt_50{
    margin-top: 50px;
}
.mt_40{
    margin-top: 40px;
}
.mt_30{
    margin-top: 30px;
}
.mt_20{
    margin-top: 20px;
}
.mt_10{
    margin-top: 10px;
}
.mt_5{
    margin-top: 5px;
}
.mb_50 {
    margin-bottom: 50px;
}
.mb_40 {
    margin-bottom: 40px;
}
.mb_30 {
    margin-bottom: 30px;
}
.mb_20 {
    margin-bottom: 20px;
}
.mb_15 {
    margin-bottom: 15px !important;
}
.mb_10 {
    margin-bottom: 10px;
}
.mb_5 {
    margin-bottom: 5px;
}
.mb_0 {
    margin-bottom: 0px !important;
}
.w_100{
    width: 100% !important;
}
.container_bx{
    width: 100%;
    padding: 0 60px;
}
.justify_content_start{
    justify-content: flex-start;
}
.justify_content_end{
    justify-content: flex-end;
}