/** ====================
 *  Contact us
=======================*/

/**--- Banner section ---**/
.contact-banner-section.container_bx {
  margin: 30px auto;
  /* width: calc(100% - 120px); */
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(../../images/contact/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-banner-section h1 {
  width: 100%;
  font-size: 62px;
  line-height: 2;
}

:lang(en) .contact-banner-section h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.contact-section.container_bx {
  padding-top: 40px;
  padding-bottom: 40px;
}

.contact-section .text {
  width: 50%;
  margin: 0 auto 50px;
}

.contact-section .text h1 {
  margin-bottom: 20px;
  text-align: center;
}

:lang(en) .contact-section .text h1 {
  font-size: 32px;
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .contact-section .text h1 {
  font-size: 62px;
}

.contact-section .text p {
  margin-bottom: 20px;
  font-size: 18px;
  font-family: var(--main-font-regular) !important;
  line-height: 2;
  text-align: center;
}

:lang(en) .contact-section .text p {
  text-transform: initial;
}

:lang(ar) .contact-section .text p {
  font-size: 24px;
}

.contact-form-bx h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 24px;
}

:lang(en) .contact-form-bx h3 {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

.contact-form-bx p {
  color: #fff;
  margin-bottom: 15px;
}

:lang(en) .contact-form-bx p {
  text-transform: initial;
}

.contact-form-bx {
  width: 100%;
  padding-inline-end: 10%;
  border-inline-end: 1px dashed #fff;
}

.contact-form-bx .ant-form-item .ant-form-item-label > label {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
}

.contact-form-bx .ant-form-item input {
  font-size: 18px;
  background: #0c2141;
  border: 1px solid #e4e4e733;
  border-radius: 80px;
  padding: 15px 18px 15px 18px;
  color: #ffffff80;
}

.contact-form-bx .ant-form-item textarea {
  border-radius: 10px;
  border: 1px solid rgba(208, 213, 221, 0.2);
  background: #0c2141;

  padding: 10px 14px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #ffffff80;
}

.contact-form-bx .ant-form-item input::placeholder {
  color: #ffffff80;
}

:lang(en) .contact-form-bx .ant-form-item .ant-form-item-label > label {
  font-family: var(--main-font-regular) !important;
  text-transform: initial;
}

:lang(ar) .contact-form-bx .ant-form-item .ant-form-item-label > label {
  font-family: var(--main-font-regular) !important;
}

.contact-form-bx
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.contact-form-bx .ant-checkbox-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.contact-form-bx .ant-checkbox-group > label {
  color: #fdfffa;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.contact-form-bx .ant-checkbox-group label .ant-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid rgba(208, 213, 221, 0.2);
  background: #0c2141;
}

.contact-form-bx .ant-checkbox-wrapper {
  color: #fff;
}

:lang(en) .contact-form-bx .ant-checkbox-wrapper {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .contact-form-bx .ant-checkbox-wrapper {
  font-family: var(--main-font-regular) !important;
}

.contact-form-bx .ant-checkbox-wrapper a {
  color: #fff;
  text-decoration: underline;
}

.contact-form-bx .validation-btn {
  width: fit-content;
  margin: 20px auto;
  padding: 22px 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 60px;
  font-size: 18px;
  background-color: #09aad6;
  border-radius: 35px;
}

:lang(en) .contact-form-bx .validation-btn {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

:lang(ar) .contact-form-bx .validation-btn {
  font-family: var(--main-font-regular) !important;
  font-weight: 500;
}

.contact-form-bx .ant-space-compact {
  width: 100%;
}

.contact-form-bx .ant-select-single.ant-select-lg {
  width: 150px;
}

.contact-section .details-text {
  padding-inline-start: 20px;
}

.contact-section .details-text h1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-section .details-text ul {
  padding: 0;
  margin-bottom: 30px;
}

.contact-section .details-text ul li {
  font-size: 18px;
  margin-inline-start: 60px;
  font-weight: 400;
  font-style: normal;
  padding-bottom: 7px;
  line-height: 21.5px;
  font-family: var(--main-font-regular) !important;
}

:where(.css-dev-only-do-not-override-i46qwz).ant-checkbox-wrapper {
  align-items: center;
}
