.empty-page {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.empty-page svg {
  height: 400px;
  width: 100%;
}

.empty-page h1 {
  font-size: 52px;
  font-family: var(--main-font-bold) !important;
  text-align: center;
}
