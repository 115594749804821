/** ====================
 *  Services us
=======================*/

.blog-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-text .small-title {
  font-family: var(--main-font-regular) !important;
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
  justify-content: flex-start;
}

.blog-text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  background-color: #09aad6;
}

:lang(en) .blog-text .small-title {
  text-transform: initial;
}

:lang(ar) .blog-text .small-title {
  font-size: 16px;
  font-family: "Tajawal", sans-serif !important;
}

.servicedetails-list {
  margin-inline-start: 20px;
}

.servicedetails-list li {
  font-size: 18px;
  padding: 10px;
  font-family: var(--main-font-regular) !important;
}

.service-details-wrapper .service-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.service-details-wrapper .justify_content_end {
  height: 100%;
}

.data-text-bx.container_bx {
  /* padding-top: 50px; */
  padding-bottom: 30px;
}

.data-text-bx .blog-text h1 {
  font-size: 52px;
  margin-bottom: 50px;
}

.data-text-bx .blog-text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .data-text-bx .blog-text h1 {
  font-size: 42px;
}

.data-text-bx .blog-text h3 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #09aad6;
}

:lang(en) .data-text-bx .blog-text h3 {
  text-transform: initial;
}

:lang(ar) .data-text-bx .blog-text h3 {
  font-size: 16px;
}

.data-text-bx .blog-text p {
  font-size: 18px;
  font-family: var(--main-font-regular) !important;
  margin-bottom: 20px;
  line-height: 2;
}

:lang(en) .data-text-bx .blog-text p {
  text-transform: initial;
}

:lang(ar) .data-text-bx .blog-text p {
  font-size: 15px;
}

.screen-frame-bx {
  width: 100%;
  /* margin-top: -150px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen-frame-bx img {
  width: 50%;
}

.sass-content {
  padding: 50px 50px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;
  background-image: linear-gradient(#09aad600, #09aad675);
}
