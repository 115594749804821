/** ====================
 *  Home
=======================*/

/**--- Banner section ---**/
.banner-section {
  margin-top: 40px;
}

.banner-section .avatar-wrapper {
  width: 135px;
  height: 135px;
}

.banner-section .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-section .text .typed {
  text-align: center;
}

.banner-section .text h1 {
  margin-bottom: 30px;
  font-size: 50px;
}

:lang(en) .banner-section .text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
  line-height: 1.25;
  font-weight: 700;
}

:lang(ar) .banner-section .text h1 {
  font-size: 42px;
}

.banner-section .text p {
  font-size: 36px;
  margin-bottom: 30px;
  line-height: 2;
}

.banner-section .text ul {
  margin-bottom: 30px;
  line-height: 2;
}

.banner-section .text ul li {
  list-style: decimal inside;
  margin-bottom: 10px;
  font-size: 30px;
}

.banner-section .get-start-btn {
  padding-bottom: 50px;
}

:lang(en) .banner-section .text p {
  font-size: 18px;
  text-transform: initial;
}

:lang(ar) .banner-section .text p {
  font-size: 18px;
}

.banner-section .get-start-btn a {
  color: #fff;
  padding: 15px 35px;
  border-radius: 15px;
  text-decoration: none;
  display: inline-flex;
  gap: 15px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  background-color: #09aad6;
  border: 1px solid #09aad6;
  transition: all 0.3s ease-in-out;
}

.banner-section .get-start-btn a:hover {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

:lang(en) .banner-section .get-start-btn a {
  text-transform: initial;
}

.banner-section .get-start-btn a img {
  width: 15px;
}

:lang(ar) .banner-section .get-start-btn a img {
  transform: rotate(180deg);
}

.banner-section .banner-img {
  height: 100%;
  align-self: flex-end;
}

.banner-section .banner-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/**--- Welcome section ---**/
.welcome-section .welcome-bx {
  padding: 50px 50px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;
  /* background-image: linear-gradient(#09aad600, #09aad6); */
}

.welcome-section .text h1 {
  margin-bottom: 50px;
}

:lang(en) .welcome-section .text h1 {
  font-size: 40px;
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .welcome-section .text h1 {
  font-size: 40px;
}

.welcome-section .text p {
  margin-bottom: 20px;
  line-height: 2;
}

:lang(en) .welcome-section .text p {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .welcome-section .text p {
  font-size: 15px;
}

.welcome-section .text .small-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.welcome-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  margin-top: 0px;
  background-color: #09aad6;
}

:lang(en) .welcome-section .text .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .welcome-section .text .small-title {
  font-size: 16px;
  font-family: var(--main-font-regular) !important;
}

.welcome-section .counter-bx {
  display: grid;
  gap: 35px;
  /* grid-template-columns: 1fr 1fr; */
  height: 100%;
  align-items: center;
}

.welcome-section .counter-bx .item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.welcome-section .counter-bx .item p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.welcome-section .counter-bx .item span {
  font-size: 64px;
  margin: 0px 15px;
  font-family: var(--main-font-regular);
  font-weight: 600;
}

/**--- Who section ---**/
.who-section.container_bx {
  padding-top: 40px;
  padding-bottom: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -40px;
}

.who-section .who-bx {
  margin-bottom: 20px;
}

.who-section .collapse-container .image-wrapper {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 45px;
  background: linear-gradient(
    132deg,
    rgba(132, 181, 255, 0) 1.8%,
    rgba(18, 41, 75, 0.25) 81.79%
  );
  box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.11) inset;
}

.who-section .text h1 {
  margin-bottom: 50px;
}

:lang(en) .who-section .text h1 {
  font-size: 52px;
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

:lang(ar) .who-section .text h1 {
  font-size: 42px;
}

.who-section .text p {
  margin-bottom: 20px;
  line-height: 2;
}

:lang(en) .who-section .text p {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .who-section .text p {
  font-size: 15px;
}

.who-section .text .small-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.who-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  margin-top: 0px;
  background-color: #09aad6;
}

:lang(en) .who-section .text .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .who-section .text .small-title {
  font-size: 16px;
  font-family: var(--main-font-regular) !important;
}

.who-section .collapse-container {
  padding-inline-start: 100px;
}

.who-section .collapse-container .title-bar {
  color: #fff;
  display: flex;
  gap: 25px;
  align-items: center;
  padding: 10px 20px;
}

:lang(en) .who-section .collapse-container .title-bar {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .who-section .collapse-container .title-bar {
  font-size: 18px;
  font-family: var(--main-font-bold) !important;
}

.who-section .collapse-container .title-bar span {
  font-size: 24px;
  font-weight: 700;
}

.who-section .collapse-container .title-bar img {
  width: 40px;
}

.who-section .ant-collapse .ant-collapse-content {
  color: #fff;
  background-color: transparent;
}

.who-section
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: #fff;
}

:lang(en)
  .who-section
  .ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-arrow {
  transform: rotate(180deg);
}

:lang(ar)
  .who-section
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  transform: rotate(180deg);
}

.who-section .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.who-section .ant-collapse {
  border: 0;
}

.who-section .ant-collapse .ant-collapse-content {
  border-top: unset;
}

.who-section .ant-collapse > .ant-collapse-item {
  margin-bottom: 30px;
  border-radius: 8px;
  border: 1px solid #09aad6;
}

.who-section .who-bx .text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.services-section .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  padding: 0 10px;
  background-image: linear-gradient(rgba(9, 170, 214, 0), #09aad6);
}

/**--- Services section ---**/
.services-section.container_bx {
  padding-top: 40px;
  padding-bottom: 0px;
}

.services-section {
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -40px;
}

.services-section .text {
  width: 65%;
  margin: 0 auto 0px;
}

.services-section .text h1 {
  font-size: 52px;
  margin: 0px auto 25px auto;
  width: 80%;
  text-align: center;
}

:lang(en) .services-section .text h1 {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

:lang(ar) .services-section .text h1 {
  font-size: 52px;
}

.services-section .text p {
  margin-bottom: 20px;
  line-height: 2;
  text-align: center;
  font-size: 18px;
}

:lang(en) .services-section .text p {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

.services-section .text .small-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
  justify-content: center;
}

.services-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  margin-top: 0px;
  background-color: #09aad6;
}

:lang(en) .services-section .text .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .services-section .text .small-title {
  font-size: 16px;
  font-family: var(--main-font-regular) !important;
}

.services-section .tabs-bx {
  padding: 50px 50px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;
  background: linear-gradient(180deg, rgba(15, 37, 70, 0) 0%, #0f2545 100%);
}

.services-section .tabs-bx .ant-tabs-tab.ant-tabs-tab-active {
  background-image: linear-gradient(#09aad600, #09aad650);
}

.services-section .tabs-bx .ant-tabs .ant-tabs-tab {
  padding: 12px;
  margin: 0;
  width: calc(100% / 6);
  height: 150px;
  justify-content: center;
}

@media (max-width: 1200px) {
  .services-section .tabs-bx .ant-tabs .ant-tabs-tab {
    width: calc(100% / 3);
  }

  .services-section .ser-tab-item svg {
    width: 50px;
    height: 50px;
  }

  .services-section .ser-tab-item .tab-text {
    font-size: 14px !important;
  }

  .ant-tabs-nav {
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .services-section .tabs-bx .ant-tabs .ant-tabs-tab {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .services-section .tabs-bx .ant-tabs .ant-tabs-tab {
    width: 50%;
  }
}

.services-section .tabs-bx .ant-tabs .ant-tabs-tab-active {
  border: none;
  outline: none;
}

.services-section .ser-tab-item {
  display: flex;
  gap: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.services-section .ant-tabs .ant-tabs-tab-btn {
  height: 100%;
}

.services-section .ant-tabs-tab.ant-tabs-tab-active svg * {
  fill: #09aad6;
  outline: #09aad6;
}

.services-section .ser-tab-item .tab-text {
  color: #cecece;
  transition: all 0.3s ease-in-out;
  font-size: 16px !important;
  white-space: wrap;
  text-align: center;
  width: 100%;
}

.services-section .ant-tabs-tab.ant-tabs-tab-active .ser-tab-item .tab-text {
  color: #fff !important;
}

:lang(en) .services-section .ser-tab-item .tab-text {
  font-size: 20px;
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
  font-weight: 500;
}

:lang(ar) .services-section .ser-tab-item .tab-text {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--main-font-medium) !important;
}

.services-section .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.services-section .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
}

:lang(ar) .services-section .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
:lang(ar) .services-section .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  flex-direction: row-reverse;
}

.services-section .text-bx h1 {
  margin-bottom: 30px;
  color: #fff;
  font-size: 52px;
}

.services-section .text-bx h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.services-section .text-bx p {
  margin-bottom: 20px;
  line-height: 2;
  color: #fff;
}

:lang(en) .services-section .text-bx p {
  font-size: 18px;
  text-transform: initial;
}

:lang(ar) .services-section .text-bx p {
  font-size: 18px;
}

.services-section .text-bx .small-title {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #fff;
  padding-top: 40px;
}

.services-section .text-bx .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  margin-top: 0px;
  background-color: #09aad6;
}

.services-section .text-bx .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

.services-section .text-bx .see-more {
  margin-top: 20px;
}

.services-section .text-bx .see-more a {
  padding: 15px 30px;
  display: flex;
  gap: 30px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  align-items: center;
}

.services-section .text-bx .see-more a img {
  width: 20px;
}

.services-section .text-bx .see-more a {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

:lang(ar) .services-section .text-bx .see-more a img {
  transform: rotate(270deg);
}

.services-section .tab-content-img {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-section .tab-content-img svg {
  width: 90%;
  height: 500px;
}

.services-section .text-bx {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/**--- Products section ---**/
.products-section.container_bx {
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.products-section .text {
  width: 50%;
  margin: 0 auto 50px;
}

.products-section .text h1 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 52px;
}

.products-section .text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.products-section .text p {
  font-size: 18px;
  margin-bottom: 20px !important;
  line-height: 2;
  text-align: center;
  font-family: var(--main-font-regular) !important;
}

.grid-bx .shaari-wrapper {
  overflow: hidden;
  height: 350px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 25px;
}

.grid-bx .item {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: url(../../images/products/products-filter.png);
  background-size: 100% 105%;
  border-radius: 25px;
  margin-bottom: 20px;
  position: relative;
  padding: 0px;
  overflow: hidden;
  display: inline-block;
}

.grid-bx .item img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
}

.grid-bx .item .item-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px 5px 20px 20px;
  margin-bottom: -30px;
  border-radius: 5px;
  background-color: #09aad6;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  opacity: 0;
  box-shadow: 0px 0px 5px #00000020;
  transition: all 0.3s ease-in-out;
}

.grid-bx .item:hover .item-data {
  opacity: 1;
  transform: translateY(-30px);
}

.grid-bx .item.h_100 {
  height: 725px;
}

.grid-bx .item.h_50 {
  height: 350px;
}

.grid-bx .twice-bx {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.grid-bx .item .projects-num {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 30px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: calc(100% - 40px);
  background-color: #12294b;
}

.grid-bx .item .projects-num h1 {
  font-size: 32px;
  color: #fff;
}

.grid-bx .item .projects-num p {
  color: #fff;
}

.grid-bx .item .projects-num h1 {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

:lang(ar) .grid-bx .item h1 {
  line-height: 3rem;
}

:lang(ar) .grid-bx .item .item-data h1 {
  line-height: 4rem;
}

:lang(ar) .grid-bx .item .projects-num h1 {
  font-size: 40px;
}

:lang(ar) .grid-bx .item .projects-num p {
  font-size: 20px;
}

.grid-bx .item .projects-num a {
  height: 46px;
  padding: 0px 30px;
  border-radius: 10px;
  background-color: #fff;
  color: #12294b;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.grid-bx .item .projects-num a {
  font-size: 18px;
  font-weight: 700;
  font-family: var(--main-font-medium) !important;
  text-transform: initial;
}

.grid-bx .item .projects-num a img {
  width: 20px;
  object-fit: contain;
}

.products-section .see-more {
  padding: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products-section .see-more a {
  display: flex;
  gap: 10px;
  padding: 15px 25px;
  color: #fff;
  text-decoration: none;
}

.products-section .see-more a {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
  font-weight: 600;
}

.grid-bx .item .projects-num a p {
  color: #12294b;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products-section .see-more a img {
  width: 25px;
}

:lang(ar) .products-section .see-more a img {
  transform: rotate(270deg);
}

/**--- Who section ---**/
.blog-section.container_bx {
  padding-top: 20px;
  padding-bottom: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -40px;
}

:lang(en) .blog-section.container_bx {
  background-image: url(../../images/ellipse2.png);
  background-position-x: left;
}

:lang(ar) .blog-section.container_bx {
  background-image: url(../../images/ellipse2-right.png);
  background-position-x: right;
}

.blog-section .text h1 {
  margin-bottom: 30px;
  font-size: 52px;
}

.blog-section .text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.blog-section .text p {
  margin-bottom: 20px;
  line-height: 2;
  margin-bottom: 0;
  font-size: 18px !important;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .blog-section .text p {
  font-size: 15px;
}

.blog-section .text .small-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.blog-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  margin-top: 0px;
  background-color: #09aad6;
}

:lang(en) .blog-section .text .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .blog-section .text .small-title {
  font-size: 16px;
  font-family: var(--main-font-regular) !important;
}

.blog-section .carousel-container {
  padding: 0 0 20px;
  margin-top: -30px;
}

.blog-section .carousel-container .carousel-item,
.blog-section .carousel-container .carousel-item a {
  height: 100%;
  width: 95%;
  margin: auto;
}

.blog-section .carousel-container .carousel-item a {
  text-decoration: none;
  margin: 0 10px;
  padding: 15px;
  border-radius: 15px;
  background-color: #fff;
  display: inline-block;
}

.blog-section .carousel-container .carousel-item .card-bx {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.blog-section .carousel-container .carousel-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.blog-section .carousel-container .carousel-item h3 {
  color: #fff;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #09aad6;
  display: inline-block;
  width: fit-content;
  font-weight: 600;
}

:lang(en) .blog-section .carousel-container .carousel-item h3 {
  font-size: 12px;
  text-transform: initial;
}

:lang(ar) .blog-section .carousel-container .carousel-item h3 {
  font-size: 13px;
}

.blog-section .carousel-container .carousel-item p {
  color: #181a2a;
  font-size: 18px;
  margin-bottom: 15px;
}

.blog-section .carousel-container .carousel-item p {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.blog-section .see-more {
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-section .see-more a {
  display: flex;
  gap: 10px;
  padding: 15px 25px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.blog-section .see-more a {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

.blog-section .see-more a img {
  width: 25px;
}

:lang(ar) .blog-section .see-more a img {
  transform: rotate(270deg);
}

:lang(en) .blog-section .react-multiple-carousel__arrow--right {
  top: 0px;
  right: 10px;
}

:lang(ar) .blog-section .react-multiple-carousel__arrow--right {
  top: 0px;
  right: unset;
  left: 10px;
  transform: rotate(180deg);
}

:lang(en) .blog-section .react-multiple-carousel__arrow--left {
  top: 0;
  left: unset;
  right: 60px;
}

:lang(ar) .blog-section .react-multiple-carousel__arrow--left {
  top: 0;
  right: unset;
  left: 60px;
  transform: rotate(180deg);
}

.blog-section .react-multiple-carousel__arrow:hover {
  color: #181a2a !important;
}

.blog-section .react-multiple-carousel__arrow:hover::before {
  color: #181a2a !important;
}

.blog-section .react-multi-carousel-list {
  padding-top: 60px;
}

.blog-section .react-multiple-carousel__arrow {
  min-width: 30px;
  min-height: 30px;
  font-size: 18px;
  background-color: #fff;
}

.blog-section .react-multiple-carousel__arrow:hover::before {
  color: #fff;
}

.blog-section .react-multiple-carousel__arrow::before {
  color: #181a2a;
}

/**--- Discover section ---**/
.discover-section.container_bx {
  padding-top: 0px;
  padding-bottom: 30px;
}

.discover-section {
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -40px;
}

.discover-section .discover-wrapper .overlay-image {
  background: url(../../images/got-an-idea.png);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.discover-section .discover-img {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.discover-section .discover-img img {
  width: 90%;
  height: auto;
  margin-inline-start: auto;
  max-height: 800px;
  min-height: 500px;
  margin-bottom: 100px;
  object-fit: contain;
}

.discover-section .descover-form h1 {
  color: #fff;
  font-size: 52px;
  line-height: 2;
  margin-bottom: 50px;
}

.discover-section .descover-form h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.descover-form {
  width: 100%;
}

.descover-form .ant-form-item .ant-form-item-label > label {
  color: #fff;
}

.descover-form .ant-form-item .ant-form-item-label > label {
  font-family: var(--main-font-regular) !important;
  text-transform: initial;
}

.descover-form
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.descover-form .ant-checkbox-wrapper {
  color: #fff;
}

:lang(en) .descover-form .ant-checkbox-wrapper {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .descover-form .ant-checkbox-wrapper {
  font-family: var(--main-font-regular) !important;
}

.descover-form .ant-checkbox-wrapper a {
  color: #fff;
  text-decoration: underline;
}

.descover-form .validation-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 60px;
  font-size: 18px;
  background-color: #09aad6;
}

:lang(en) .descover-form .validation-btn {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

:lang(ar) .descover-form .validation-btn {
  font-family: var(--main-font-medium) !important;
  font-weight: 500;
}

:lang(ar) .descover-form .validation-btn img {
  transform: rotate(180deg);
}

/**--- Companies section ---**/
.companie-section.container_bx {
  padding-top: 30px;
  padding-bottom: 30px;
}

.companie-section .title h3 {
  color: #fff;
  text-align: center;
}

.ant-collapse-content-box p {
  font-family: var(--main-font-regular) !important;
  font-size: 16px;
  text-align: start;
  margin-inline-start: 20px;
  padding: 5px;
}

:where(
    .css-dev-only-do-not-override-i46qwz
  ).ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  padding: 10px;
}

:where(
    .css-dev-only-do-not-override-i46qwz
  ).ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon
  svg {
  transition: all 0.3s ease;
}

:where(.css-dev-only-do-not-override-i46qwz).ant-tabs > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-i46qwz).ant-tabs > div > .ant-tabs-nav {
  padding: 0px 20px;
}

.ant-tabs-content .ant-tabs-tabpane {
  animation: fadeEffect 1s;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.react-multiple-carousel__arrow::before,
.react-multiple-carousel__arrow::after {
  font-size: 16px;
}
