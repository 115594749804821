@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700;800;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap"); */

@font-face {
  font-family: "Poppins-Thin";
  src: url("../fonts/Poppins-Thin.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: TheSans;
  src: url("../fonts/TheSans-Plain.otf");
}

@font-face {
  font-family: "Aeonik-Trail";
  src: url("../fonts/AeonikTRIAL-Regular.otf");
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-Regular.otf");
}

@font-face {
  font-family: "Aeonik-Medium";
  src: url("../fonts/Aeonik-Medium.ttf");
}

@font-face {
  font-family: "Aeonik-Bold";
  src: url("../fonts/Aeonik-Bold.otf");
}

@font-face {
  font-family: "Montserrat-Thin";
  src: url("../fonts/Montserrat/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
}

/** ====================
 *  Variables
=======================*/
:root {
  --main-blue: #0f54fb;
  --dark-blue: #002882;
  --orange-color: #f26d53;

  --main-font-thin: "Montserrat-Thin", "Aeonik-Trail", sans-serif;
  --main-font-regular: "Montserrat-Regular", "Aeonik", sans-serif;
  --main-font-medium: "Montserrat-Medium", "Aeonik-Medium", sans-serif;
  --main-font-bold: "Montserrat-Bold", "Aeonik-Bold", sans-serif;
  scrollbar-face-color: #c9c3c3;
  /* Firefox 63 compatibility */
  scrollbar-track-color: rgb(46, 54, 69);
  /* Firefox 63 compatibility */
  scrollbar-color: #f4f7f7;
  scrollbar-width: thin;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f4f7f7;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #c9c3c3;
}

html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
  font-family: var(--main-font-regular);
}

body {
  color: #fff;
  background-color: #041735;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: var(--main-font-regular);
  font-size: 16px;
}

body:lang(en) {
  font-family: var(--main-font-regular);
}

body:lang(ar) {
  direction: rtl;
  font-family: var(--main-font-regular);
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body:lang(ar) h1,
body:lang(ar) h2,
body:lang(ar) h3,
body:lang(ar) h4,
body:lang(ar) h5,
body:lang(ar) h6,
body:lang(ar) p,
body:lang(ar) span,
body:lang(ar) div,
body:lang(ar) ul,
body:lang(ar) li,
body:lang(ar) td,
body:lang(ar) tr,
body:lang(ar) th,
body:lang(ar) a {
  font-family: var(--main-font-regular);
}

body:lang(en) h1,
body:lang(en) h2,
body:lang(en) h3,
body:lang(en) h4,
body:lang(en) h5,
body:lang(en) h6,
body:lang(en) p,
body:lang(en) span,
body:lang(en) div,
body:lang(en) ul,
body:lang(en) li,
body:lang(en) td,
body:lang(en) tr,
body:lang(en) th,
body:lang(en) a {
  font-family: var(--main-font-regular);
  line-height: 1.5;
}

.main-bx {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  background: url(../images/ellipse-header.svg) no-repeat center top;
}

.main-header-image svg {
  width: 100%;
  height: 100%;
}

.main-bx-top {
  background-position: center -250px !important;
}

.main-dir-rtl {
  direction: rtl;
}

.main-dir-ltr {
  direction: ltr;
}

/** Header Section **/
.header-section {
  position: fixed;
  z-index: 999;
}

/**--- Footer section ---**/
.footer-section.container_bx {
  padding-top: 30px;
  padding-bottom: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: auto;
  background-image: linear-gradient(
    180deg,
    rgba(15, 37, 70, 0) 0%,
    #0f2545 100%
  );
}

.companies-slider {
  margin-bottom: 75px;
}

.companies-slider .company-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.companies-slider .carousel-item,
.companies-slider .company-img {
  height: 100%;
}

.companies-slider .company-img img {
  width: 90%;
  height: 125px;
  object-fit: contain;
  pointer-events: none;
  background: #fff;
  padding: 2.5px;
  border-radius: 10px;
  border: 1.5px solid #09aad6;
}

.footer-items {
  padding: 30px 0;
}

.footer-items .item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-items .item img {
  width: 40%;
}

.footer-items .item p {
  font-size: 14px;
  line-height: 2;
  width: 70%;
}

:lang(en) .footer-items .item {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

.footer-items .item h3 {
  color: #b5b5b5;
  font-size: 18px;
}

:lang(en) .footer-items .item h3 {
  text-transform: capitalize;
  font-family: var(--main-font-bold) !important;
}

.footer-items .item ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.footer-items .item ul li {
  list-style: none;
}

.footer-items .item ul li p {
  color: #fff;
  cursor: pointer;
}

:lang(en) .footer-items .item ul li p {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

.footer-form {
  margin-top: 30px;
}

.footer-form .send-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 46px;
  font-size: 18px;
  background-color: #09aad6;
  font-size: 14px;
}

:lang(en) .footer-form .send-btn {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

.copy-right {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  margin-top: 30px;
  border-top: 1px dashed #fff;
}

:lang(en) .copy-right {
  font-size: 13px;
  text-transform: initial;
}

:lang(ar) .copy-right {
  font-size: 13px;
}

:lang(ar) .rotate-arrow {
  transform: rotate(180deg);
}

:lang(ar) .discover-section .discover-wrapper .digital-text {
  margin: 20px 0;
  display: inline-block;
}

.menu-bx .choose-lang.hide-desktop {
  display: none;
}

.title-text h3 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #09aad6;
}

.title-text h1 {
  margin-bottom: 30px;
  font-size: 52px;
  font-family: var(--main-font-bold) !important;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
}

.title-text p {
  margin-bottom: 30px;
  line-height: 2;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .w-75,
  .w-50,
  .w-25 {
    width: 100%;
  }

  .my-50 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.ant-tabs-nav-list {
  flex-wrap: wrap;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #09aad6;
}

@media screen and (max-width: 992px) {
  .ant-tabs .ant-tabs-ink-bar {
    display: none !important;
  }
}

.mouse-cursor *,
.mouse-cursor {
  z-index: 99999;
}

@media screen and (max-width: 992px) {
  .mouse-cursor * {
    display: none !important;
  }
}

.typed {
  margin-bottom: 30px;
}

.typed span {
  margin-bottom: 30px;
  font-size: 70px;
  font-family: var(--main-font-bold) !important;
  line-height: 1.5;
  font-weight: 700;
  /* text-transform: uppercase; */
}

@media screen and (max-width: 992px) {
  .typed span {
    font-size: 30px;
  }
}

.react-tabs .react-tabs__tab-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: calc(100% / 6 - 10px);
  cursor: pointer;
  height: 150px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  box-shadow: none;
  outline: none;
}

.react-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  position: relative;
  background: linear-gradient(#09aad600, #09aad650);
  color: #fff;
}

.react-tabs
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected::after {
  content: "";
  position: absolute;
  bottom: -1;
  left: 0;
  right: 0;
  height: 1px;
  background: #09aad6;
}

@media screen and (max-width: 1200px) {
  .react-tabs .react-tabs__tab-list .react-tabs__tab {
    width: calc(100% / 3 - 10px);
  }
}

@media screen and (max-width: 992px) {
  .react-tabs .react-tabs__tab-list .react-tabs__tab {
    width: calc(100% / 2 - 10px);
  }
}
