/** ====================
 *  About us
=======================*/

/**--- Banner section ---**/
.page-banner-section.container_bx {
  margin: 0px auto;
  /* width: calc(100% - 120px); */
  padding-top: 100px;
  padding-bottom: 100px;
  /* background-image: url(../../images/about-banner.png); */
  background-repeat: no-repeat;
  background-size: cover;
}

.page-banner-section h1 {
  width: 100%;
  font-size: 62px;
  line-height: 1.5;
}

:lang(en) .page-banner-section h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

/**--- Who text section ---**/
.who-text-section.container_bx {
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
}

.who-text-section .who-text-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.who-text-section .who-text-img img {
  width: 90%;
  padding-bottom: 60px;
}

.who-text-section .text h1 {
  font-size: 52px;
  margin-bottom: 60px;
  line-height: 1.5;
}

.who-text-section .text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.who-text-section .text p {
  margin-bottom: 20px;
  line-height: 2;
}

.who-text-section .text p {
  font-size: 18px;
  text-transform: initial;
  color: #e6e6e6;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.who-text-section .text .small-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.who-text-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  background-color: #09aad6;
}

:lang(en) .who-text-section .text .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .who-text-section .text .small-title {
  font-size: 16px;
  font-family: var(--main-font-regular) !important;
}

.who-text-section .details-text {
  margin-bottom: 30px;
}

.who-text-section .details-text h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
}

:lang(en) .who-text-section .details-text h3 {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

.who-text-section .details-text h3 img {
  width: 40px;
}

.who-text-section .details-text p {
  font-size: 18px;
  line-height: 2;
  padding-inline-start: 60px;
}

:lang(en) .who-text-section .details-text p {
  text-transform: initial;
}

/**--- What we do section ---**/
.what-we-do-section.container_bx {
  padding-top: 20px;
  padding-bottom: 40px;
}

.what-we-do-section .text {
  max-width: 800px;
  margin: 0 auto 50px;
}

.what-we-do-section .text h1 {
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: center;
}

:lang(en) .what-we-do-section .text h1 {
  font-size: 52px;
  margin-bottom: 60px;
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .what-we-do-section .text h1 {
  font-size: 32px;
}

.what-we-do-section .text p {
  margin-bottom: 20px;
  line-height: 2;
  text-align: center;
}

:lang(en) .what-we-do-section .text p {
  font-size: 15px;
  text-transform: initial;
}

:lang(ar) .what-we-do-section .text p {
  font-size: 15px;
}

.what-we-do-section .text .small-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
  justify-content: center;
}

.what-we-do-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  background-color: #09aad6;
}

:lang(en) .what-we-do-section .text .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .what-we-do-section .text .small-title {
  font-size: 16px;
  font-family: var(--main-font-regular) !important;
}

.what-we-do-section .what-we-do-text {
  padding: 50px 50px 50px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;
  /* background-image: linear-gradient(rgba(9, 170, 214, 0), #09aad6); */
}

.what-we-do-section .do-img {
  height: 100%;
  display: flex;
  align-items: center;
}

.what-we-do-section .do-img img {
  width: 90%;
}

.what-we-do-section .do-text {
  width: 100%;
}

.what-we-do-section .do-text h1 {
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.what-we-do-section .do-text h1 .color {
  color: #fff;
}

:lang(en) .what-we-do-section .do-text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.what-we-do-section .do-text p {
  line-height: 2;
  font-size: 18px;
}

:lang(en) .what-we-do-section .do-text p {
  text-transform: initial;
}

/**--- Why text section ---**/
.why-text-section.container_bx {
  /* padding-top: 40px; */
  padding-bottom: 40px;
}

.why-text-section .who-text-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-text-section .who-text-img img {
  width: 90%;
  height: 400px;
  object-fit: contain;
}

.why-text-section .text h1 {
  margin-bottom: 50px;
  font-size: 52px;
}

:lang(en) .why-text-section .text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .why-text-section .text h1 {
  font-size: 36px;
}

.why-text-section .text p {
  margin-bottom: 20px;
  line-height: 2;
}

:lang(en) .why-text-section .text p {
  font-size: 15px;
  text-transform: initial;
}

:lang(ar) .why-text-section .text p {
  font-size: 15px;
}

.why-text-section .text .small-title {
  font-family: var(--main-font-regular) !important;
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.why-text-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  background-color: #09aad6;
}

:lang(en) .why-text-section .text .small-title {
  text-transform: initial;
}

:lang(ar) .why-text-section .text .small-title {
  font-size: 16px;
  font-family: "Tajawal", sans-serif !important;
}

.why-text-section .details-text {
  margin-bottom: 30px;
}

.why-text-section .details-text h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 18px;
}

:lang(en) .why-text-section .details-text h3 {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

.why-text-section .details-text h3 img {
  width: 40px;
}

.why-text-section .details-text p {
  font-size: 18px;
  line-height: 2;
}

:lang(en) .why-text-section .details-text p {
  text-transform: initial;
}

/**--- Team section ---**/
.team-section.container_bx {
  padding-top: 40px;
  padding-bottom: 40px;
}

.team-section .text {
  margin: 0 auto 50px;
}

.team-section .text h1 {
  font-size: 52px;
  margin-bottom: 20px;
  text-align: center;
}

:lang(en) .team-section .text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .team-section .text h1 {
  font-size: 32px;
}

.team-section .text p {
  font-size: 18px;
  margin-bottom: 20px;
  width: 50%;
  margin: 0 auto 20px;
  line-height: 2;
  text-align: center;
  text-transform: initial;
}

:lang(ar) .team-section .text p {
  font-size: 15px;
}

.team-section .text .small-title {
  font-size: 18px;
  font-family: var(--main-font-regular) !important;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
  justify-content: center;
}

.team-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  background-color: #09aad6;
}

:lang(en) .team-section .text .small-title {
  text-transform: uppercase;
}

:lang(ar) .team-section .text .small-title {
  font-size: 16px;
  font-family: "Tajawal", sans-serif !important;
}

.team-items {
  padding: 40px 50px 50px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;
}

.team-items .member-item {
  display: flex;
  gap: 15px;
  flex-direction: column;
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 20px;
  border: 1px solid #83838350;
  background: linear-gradient(
    #83838300 10%,
    #83838310 30%,
    #83838330 50%,
    #83838330 70%,
    #83838315 100%
  );
  transition: all 0.3s ease-in-out;
  height: 100%;
}

/* on .member-item i need to make animation on hover a circle that runs around the card by before selector */
.team-items .member-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  width: 0px;
  height: 0px;
  border-radius: 15px;
  background-color: transparent;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
}

.team-items .member-item:hover::after {
  visibility: visible;
  top: 0px;
  left: 0px;
  width: calc(50% - 10px);
  height: calc(50% - 10px);
  background-color: #09aad6;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.team-items .member-item:hover {
  transform: translateY(-10px);
}

.team-items .member-item img {
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  object-fit: contain;
  object-position: bottom;
  transition: all 0.3s ease-in-out;
}

.team-items .member-item h3 {
  font-size: 24px;
  margin-top: 20px;
  color: #ffffff;
}

:lang(en) .team-items .member-item h3 {
  text-transform: initial;
  font-family: var(--main-font-medium) !important;
}

.team-items .member-item p {
  font-size: 17px;
  color: #ffffff;
  font-weight: 400 !important;
}

:lang(en) .team-items .member-item p {
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
  font-weight: 600;
}
