.companie-section .text {
  text-align: center;
}

.companie-section .text h1 {
  margin-bottom: 60px;
  font-size: 28px;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .companie-section .text h1 {
  font-family: var(--main-font-regular) !important;
}

.companie-section .text .small-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.companie-section .text .small-title::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 2px;
  margin-inline-end: 10px;
  background-color: #09aad6;
}

.companie-section .text .small-title {
  font-size: 18px;
  text-transform: initial;
  font-family: var(--main-font-regular) !important;
}

:lang(ar) .companie-section .text .small-title {
  font-size: 16px;
  font-family: "Tajawal", sans-serif !important;
}

@media (max-width: 970px) {
  .companie-section .text h1 {
    font-size: 26px !important;
    margin: 20px auto !important;
    width: 100% !important;
    text-align: start !important;
    padding-top: 20px !important;
  }
}
