@media screen and (min-width: 2000px) {
  .container_bx {
    padding: 0 10%;
  }
}

@media screen and (min-width: 1400px) {
  .top-header .menu-content .actions-wrapper .contact-btn {
    padding: 22px 45px;
  }
  .nav-links li a {
    font-size: 18px;
    padding: 30px 25px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-links li a {
    font-size: 14px !important;
  }

  .text {
    width: 100% !important;
  }

  .blog-content-page-section .text {
    width: 100%;
  }

  .welcome-section .counter-bx .item {
    align-items: center !important;
  }

  .products-section .text {
    width: 100% !important;
  }

  .who-section .collapse-container {
    padding-inline-start: 0px;
  }

  .services-section .text {
    width: 100%;
  }
}

@media screen and (max-width: 1080px) {
  .nav-links li a {
    padding: 5px 10px !important;
    font-size: 14px !important;
  }

  .banner-section.container_bx {
    padding-top: 50px;
  }

  .menu-bx .logo {
    width: 150px;
  }
}

@media screen and (min-width: 971px) {
  .actions-items-list {
    display: none !important;
  }
}

@media screen and (max-width: 970px) {
  .contact-section .details-text ul li {
    margin-inline-start: 0px;
  }

  .what-we-do-section .what-we-do-text,
  .team-items {
    padding: 30px 10px 10px 10px;
  }

  .who-text-section .details-text h3 {
    font-size: 18px;
  }

  .who-text-section .details-text p {
    font-size: 14px;
    padding: 0;
  }

  .blog-page-section .blog-item p {
    font-size: 16px !important;
  }

  .product-details-cards .data h1 {
    font-size: 26px;
  }

  .product-details-cards .data ul li h4 {
    font-size: 18px;
  }

  .container_bx .product-details-text {
    padding: 0 !important;
  }

  .container_bx .product-details-text li,
  .product-details-cards .data ul li {
    margin-inline-start: 10px !important;
    font-size: 14px;
  }

  .product-details-cards {
    padding: 10px;
  }

  .title-text h1 {
    font-size: 26px;
  }

  .title-text p {
    font-size: 14px;
  }

  .data-text-bx .blog-text p {
    font-size: 14px;
  }

  .data-text-bx .blog-text h1 {
    font-size: 26px;
  }

  .blog-text .small-title {
    font-size: 18px;
  }

  .servicedetails-list li {
    font-size: 14px;
  }

  .companie-section .title h3 {
    font-size: 16px;
    line-height: 1.5;
  }

  .discover-section .ant-col {
    width: 100%;
  }

  .discover-section .discover-img {
    justify-content: center;
  }

  .discover-section .discover-img img {
    width: 100%;
    height: auto;
    max-height: auto;
    min-height: auto;
  }

  .descover-form .validation-btn {
    font-size: 16px;
  }

  .discover-section .descover-form h1 {
    font-size: 26px !important;
    margin-bottom: 20px;
  }

  .blog-section .see-more a {
    font-size: 14px;
  }

  .blog-section .carousel-container .carousel-item h3 {
    font-size: 10px !important;
  }

  .blog-section .carousel-container .carousel-item p {
    font-size: 14px;
  }

  .services-section .text-bx .see-more a {
    font-size: 14px;
    padding: 10px;
  }

  .services-section .tab-content-img svg {
    height: auto;
    max-height: 300px;
    width: 100%;
  }

  .welcome-section .counter-bx .item span {
    font-size: 24px !important;
  }

  .main-menu .wrapper .btn {
    display: flex;
    color: #fff;
    align-items: center;
  }

  .top-header .menu-content .actions-wrapper {
    display: none;
  }

  .top-header .nav-links li:nth-child(3) a.desktop-item {
    display: none;
  }

  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #13294b;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
    z-index: 99;
  }

  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #242526;
  }

  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }

  #menu-btn:checked ~ .top-header .nav-links {
    left: 0%;
  }

  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }

  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }

  .top-header .nav-links li {
    margin: 15px 10px;
  }

  .top-header .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1rem;
  }

  .top-header .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 100%;
  }

  /* .top-header .nav-links .desktop-item{
      display: none;
    } */
  .top-header .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: initial;
    line-height: 1rem;
  }

  .drop-menu li {
    margin: 0;
  }

  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }

  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .row .mega-links li {
    margin: 0;
  }

  .content .row header {
    font-size: 19px;
  }

  .menu-bx .choose-lang.hide-desktop {
    display: flex;
  }

  .menu-bx .choose-lang.hide-mobile {
    display: none;
  }

  .wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: end;
  }

  .menu-bx .logo {
    width: 220px;
  }

  .product-tab-links {
    flex-wrap: wrap;
  }

  .who-section .collapse-container {
    padding-inline-start: 0px;
  }
}

@media screen and (max-width: 970px) {
  .services-section .ser-tab-item .tab-text {
    font-size: 14px !important;
  }

  .services-section .ser-tab-item svg {
    width: 40px;
    height: 30px;
  }

  .ant-tabs-nav {
    padding: 0 !important;
  }

  .services-section .text .small-title {
    justify-content: flex-start;
  }

  .services-section .text h1,
  .services-section .text p {
    text-align: start;
  }

  .ant-collapse-content-box p,
  .who-section .collapse-container .title-bar,
  .who-section .collapse-container .title-bar span {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .banner-section .text p {
    font-size: 14px !important;
  }

  .banner-section .get-start-btn a {
    font-size: 14px;
  }

  .banner-section .banner-img img {
    /* display: none; */
  }

  .welcome-section .welcome-bx {
    /* margin-top: 50px; */
    padding: 50px 10px;
  }

  .services-section .tabs-bx {
    padding: 50px 10px;
  }

  .who-section .collapse-container {
    padding-inline-start: 0px;
  }

  .grid-bx .item img {
    object-fit: cover;
    object-position: top;
  }

  .grid-bx .item .projects-num a img {
    object-fit: fill;
  }

  .blog-section .react-multi-carousel-list {
    margin-top: 70px;
  }

  .descover-form {
    width: 100%;
  }

  .footer-items .item {
    margin-top: 30px;
  }

  .footer-items .item p {
    width: 100%;
  }

  .welcome-section .ant-col {
    width: 100%;
  }

  .services-section .ant-col {
    width: 100%;
  }

  .welcome-section .counter-bx {
    padding-inline-start: 0;
  }

  .welcome-section .counter-bx .item p {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
  }

  .welcome-section .counter-bx .item {
    align-items: center;
    justify-content: center;
  }

  .container_bx {
    padding: 0 20px;
  }

  .who-bx .ant-col {
    width: 100%;
  }

  .top-header.sticky-nav {
    left: 20px;
    width: calc(100% - 40px);
  }

  .blog-page-section .blog-item a {
    display: block;
  }

  .service-details-bx {
    padding: 10px;
    padding-top: 10px;
  }

  .service-details-bx .justify_content_end {
    justify-content: center;
    margin-bottom: 50px;
    /* border-bottom: 1px solid #eee; */
  }

  .blog-img {
  }

  .product-details-text .product-details-frame {
    padding: 0;
  }

  .who-text-section .who-text-img {
    margin-bottom: 50px;
    align-items: center;
  }

  .contact-banner-section.container_bx,
  .page-banner-section.container_bx {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .details-text {
    padding-inline-start: 0;
  }

  .who-text-section .details-text h3 {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .what-we-do-section .what-we-do-text {
    padding-top: 20px;
  }

  .what-we-do-section .do-text {
    width: 100%;
  }

  .why-text-section .who-text-img {
    margin-bottom: 50px;
    justify-content: center;
  }

  .contact-form-bx {
    padding-inline-end: 0;
    border-inline-end: 0;
  }

  .contact-section .text {
    margin: 0 auto;
  }

  .banner-section .text h1 {
    font-size: 30px !important;
  }

  .blog-banner-section h1,
  .contact-banner-section h1,
  .page-banner-section h1,
  .products-banner-section h1 {
    font-size: 26px;
    width: 100%;
  }

  .blog-content-page-section .text h3,
  .blog-page-section .text h3 {
    font-size: 14px !important;
  }

  .contact-section .text h1,
  .team-section .text h1,
  .why-text-section .text h1,
  .what-we-do-section .text h1,
  .who-text-section .text h1,
  .product-details-text .data h1,
  .product-details-cards .data h1,
  .product-tabs-bx .text h1,
  .service-details-bx .blog-text h1,
  .blog-content-page-section .text h1,
  .blog-page-section .text h1,
  .products-section .text h1,
  .blog-section .text h1,
  .services-section .text h1,
  .welcome-section .text h1,
  .who-section .text h1,
  .services-section .text-bx h1 {
    font-size: 26px;
    margin: 20px auto;
    width: 100%;
    text-align: center;
    /* padding-top: 20px !important; */
  }

  .contact-section .text p,
  .team-section .text p,
  .why-text-section .text p,
  .what-we-do-section .text p,
  .who-text-section .text p,
  .product-details-text .data p,
  .product-details-cards .data p,
  .product-tabs-bx .text p,
  .service-details-bx .blog-text p,
  .blog-content-page-section .text p,
  .blog-page-section .text p,
  .products-section .text p,
  .blog-section .text p,
  .services-section .text p,
  .welcome-section .text p,
  .who-section .text p,
  .services-section .text-bx p {
    font-size: 14px;
    margin: 20px auto;
    width: 100%;
    text-align: start;
  }

  .contact-section .text li,
  .team-section .text li,
  .why-text-section .text li,
  .what-we-do-section .text li,
  .who-text-section .text li,
  .product-details-text .data li,
  .product-details-cards .data li,
  .product-tabs-bx .text li,
  .service-details-bx .blog-text li,
  .blog-content-page-section .text li,
  .blog-page-section .text li,
  .products-section .text li,
  .blog-section .text li,
  .services-section .text li,
  .welcome-section .text li,
  .who-section .text li,
  .services-section .text-bx li {
    font-size: 14px !important;
    width: 100% !important;
    text-align: start !important;
  }

  .contact-section .text h4,
  .team-section .text h4,
  .why-text-section .text h4,
  .what-we-do-section .text h4,
  .who-text-section .text h4,
  .product-details-text .data h4,
  .product-details-cards .data h4,
  .product-tabs-bx .text h4,
  .service-details-bx .blog-text h4,
  .blog-content-page-section .text h4,
  .blog-page-section .text h4,
  .products-section .text h4,
  .blog-section .text h4,
  .services-section .text h4,
  .welcome-section .text h4,
  .who-section .text h4,
  .services-section .text-bx h4 {
    font-size: 16px !important;
    width: 100% !important;
    text-align: start !important;
  }

  .contact-section .text h3,
  .team-section .text h3,
  .why-text-section .text h3,
  .what-we-do-section .text h3,
  .who-text-section .text h3,
  .product-details-text .data h3,
  .product-details-cards .data h3,
  .product-tabs-bx .text h3,
  .service-details-bx .blog-text h3,
  .blog-content-page-section .text h3,
  .blog-page-section .text h3,
  .products-section .text h3,
  .blog-section .text h3,
  .services-section .text h3,
  .welcome-section .text h3,
  .who-section .text h3,
  .services-section .text-bx h3 {
    padding-top: 10px !important;
  }

  .contact-section .text,
  .team-section .text p,
  .what-we-do-section .text,
  .product-tabs-bx .text,
  .services-section .text,
  .products-section .text,
  .blog-page-section .text,
  .blog-content-page-section .text {
    width: 100%;
  }

  .cards-bx {
    grid-template-columns: 1fr;
  }

  .details-text h1 {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .blog-section .text .small-title,
  .who-section .text .small-title {
    /* justify-content: center; */
  }

  .blog-section .text h1 {
    text-align: center;
  }

  .services-section .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    overflow: auto;
  }

  .services-section
    .ant-tabs
    > .ant-tabs-nav
    .ant-tabs-nav-list::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .services-section
    .ant-tabs
    > .ant-tabs-nav
    .ant-tabs-nav-list::-webkit-scrollbar-track {
    background-color: #12294b;
  }

  .services-section
    .ant-tabs
    > .ant-tabs-nav
    .ant-tabs-nav-list::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #c9c3c3;
  }
}

@media screen and (max-width: 768px) {
  .main-bx {
    background: url(../images/ellipse-header-md.svg) no-repeat center top;
  }
}

@media screen and (max-width: 580px) {
  .main-bx-top {
    background-position: center top !important;
  }
  .grid-bx .ant-col {
    width: 100%;
  }

  .grid-bx .twice-bx {
    grid-template-columns: 1fr;
  }

  .discover-section .descover-form h1 {
    font-size: 21px !important;
  }

  .menu-bx .logo {
    width: 150px;
  }

  .menu-bx .choose-lang.hide-desktop {
    font-size: 14px;
  }

  :lang(en) .who-section .text h1,
  :lang(ar) .who-section .text h1 {
    font-size: 38px;
  }
}

@media screen and (max-width: 450px) {
  .main-bx {
    background: url(../images/ellipse-header-sm.svg) no-repeat center top;
  }
}
