.spinner-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent white background */
  z-index: 9999; /* Ensures the spinner is on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-section div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #000;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
