/** ====================
 *  Header
=======================*/
.menu-bx {
  padding: 0 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  /* background-image: linear-gradient(#0aaad600, #0aaad667); */
}

.menu-bx .logo {
  width: 220px;
  line-height: 1;
  cursor: pointer;
}

.menu-bx .logo img {
  width: 100%;
}

.menu-bx .choose-lang {
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: center;
}

.menu-bx .choose-lang span {
  font-size: 12px;
  font-weight: 400;
}

.menu-bx .choose-lang img {
  width: 15px;
  height: 15px;
}

:lang(en) .menu-bx .choose-lang span {
  font-family: TheSans;
}

:lang(ar) .menu-bx .choose-lang span {
  font-family: var(--main-font-regular);
  text-transform: initial;
}

/** ====================
 *  Menu
=======================*/
.top-header.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
  width: 100%;
  background-color: #12294b;
  background-image: none;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 6px 10px #00000050;
}

.top-header {
  width: 100%;
}

.top-header.scrolled {
  top: 0;
}

.top-header .menu-content {
  position: relative;
  padding: 0;
  margin: auto;
  display: flex;
  height: 80px;
  line-height: 80px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.top-header .menu-content .actions-wrapper {
  display: flex;
  gap: 35px;
  align-items: center;
}

.top-header .menu-content .actions-wrapper .contact-btn {
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 35px;
  cursor: pointer;
}

.top-header .menu-content .actions-wrapper .language-span {
  cursor: pointer;
}

:lang(en) .top-header .menu-content .actions-wrapper .language-span {
  font-family: "Tajawal", sans-serif !important;
}

.top-header .nav-links {
  display: inline-flex;
  margin-bottom: 0;
  padding: 0;
}

.nav-links li {
  list-style: none;
}

.nav-links li.active a {
  color: #09aad6;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  padding: 30px 10px;
  border-radius: 5px;
  text-transform: initial;
  transition: all 0.3s ease;
  font-family: var(--main-font-medium);
}

.nav-links li a.active {
  color: #09aad6;
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .actions-items-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-links .actions-items-list .contact-btn {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 35px;
  cursor: pointer;
}

.nav-links .actions-items-list .language-span {
  cursor: pointer;
  padding-inline-start: 10px;
}

.top-header .nav-links .drop-menu {
  position: absolute;
  background: #13294b;
  width: fit-content;
  line-height: 45px;
  top: 80px;
  opacity: 0;
  padding: 0;
  visibility: hidden;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  z-index: 99;
}

.top-header .nav-links li:hover .drop-menu,
.top-header .nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 80px;
  opacity: 1;
  visibility: visible;
}

.top-header .drop-menu li a {
  width: 100%;
  display: block;
  padding: 15px;
  font-weight: 400;
  border-radius: 0px;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  text-transform: initial;
  font-family: var(--main-font-regular);
}

.top-header .drop-menu li a:hover {
  background-color: #80e0fb;
  color: #13294b;
}

.top-header .mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 60px;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}

.top-header .mega-box .content {
  background: #242526;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.top-header .mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}

.top-header .content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-header .content .row header {
  color: #b2b2b2;
  font-size: 16px;
  font-weight: 500;
}

.top-header .content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.top-header .row .mega-links li {
  padding: 0 20px;
}

.top-header .row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 14px;
  display: block;
}

.top-header .row .mega-links li a:hover {
  color: #f2f2f2;
  background-color: rgba(255, 255, 255, 0.15);
}

.top-header .menu-content .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.top-header .menu-content .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.top-header input {
  display: none;
}

.top-header .body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.top-header .body-text div {
  font-size: 45px;
  font-weight: 600;
}

.labels-bx {
  display: flex;
  gap: 20px;
  align-items: center;
}

.labels-bx .item {
  display: inline-flex;
}

.labels-bx a {
  text-decoration: none;
}

.labels-bx a::first-letter {
  text-transform: initial;
}

.labels-bx .item a {
  position: relative;
  color: #fff;
  display: inline-block;
  line-height: 1;
}

.labels-bx .item a img {
  width: 26px;
  height: 26px;
}

.labels-bx .item a span {
  position: absolute;
  top: -5px;
  inset-inline-end: -5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fff;
  font-weight: 300;
  font-size: 10px;
  display: flex;
  align-items: center;
  background-color: var(--orange-color);
  justify-content: center;
}

.labels-bx .item button {
  line-height: 1;
  padding: 9px 20px;
  color: #312b29;
  font-weight: 400;
  border-radius: 10px;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  border: 0.1rem solid #d9d9d9;
}

.labels-bx .item button span.currency {
  font-weight: 600;
  text-transform: uppercase;
}

.labels-bx .item button {
  font-family: var(--main-font-regular);
}

body:lang(ar) .labels-bx .item button {
  font-family: var(--main-font-regular);
}

.labels-bx .item button img {
  width: 20px;
}

.labels-bx .item button span.num {
  position: absolute;
  top: -3px;
  inset-inline-end: -3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  color: #fff;
  font-weight: 300;
  font-size: 9px;
  display: flex;
  align-items: center;
  background-color: var(--orange-color);
  justify-content: center;
}

.labels-bx .item .position-relative {
  display: flex;
  position: relative !important;
}
