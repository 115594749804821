/** ====================
 *  Products us
=======================*/

/**--- Banner section ---**/
.products-banner-section.container_bx {
  margin: 30px auto;
  padding-top: 150px;
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.products-banner-section h1 {
  width: 100%;
  font-size: 62px;
  line-height: 2;
}

:lang(en) .products-banner-section h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.products-tabs-section.container_bx {
  padding-top: 5px;
}

.product-tabs-bx .text {
  width: 50%;
  margin: 0 auto 50px;
}

.product-tabs-bx .text h1 {
  font-size: 52px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.product-tabs-bx .text h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .product-tabs-bx .text h1 {
  font-size: 52px;
  font-family: var(--main-font-bold) !important;
}

.product-tabs-bx .text p {
  font-size: 18px;
  font-family: var(--main-font-regular) !important;
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: center;
  color: #fff;
}

:lang(en) .product-tabs-bx .text p {
  text-transform: initial;
}

:lang(ar) .product-tabs-bx .text p {
  font-size: 18px;
}

.products-tabs-section .ant-tabs-top > .ant-tabs-nav::before,
.products-tabs-section .ant-tabs-bottom > .ant-tabs-nav::before,
.products-tabs-section .ant-tabs-top > div > .ant-tabs-nav::before,
.products-tabs-section .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0;
}

.project-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(19, 41, 75, 0) 0%, #13294b 100%);
}

.product-tab-links {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.product-tab-links a {
  color: #fff;
  padding: 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 6px;
}

.product-tab-links a.active {
  color: #09aad6;
}

.shaari-bg-image {
  background: url(../../images/products/home-shaari.png) no-repeat top center /
    cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.product-details-text,
.product-details-cards {
  margin-top: 100px !important;
  margin-bottom: 50px !important;
}

.product-details-text .product-details-frame {
  height: 100%;
  width: 100%;
  /* padding-inline: 5%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-details-text .product-details-frame img {
  width: 100% !important;
  height: 100% !important;
  max-height: 600px;
  object-fit: contain;
}

.product-details-text .data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-details-text .data h3 {
  color: #fff;
  font-size: 20px;
  font-family: var(--main-font-regular) !important;
  font-weight: normal;
  margin-bottom: 20px;
}

.product-details-text .data h1 {
  color: #fff;
  font-size: 46px;
  margin-bottom: 30px;
}

.product-details-text .data h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

:lang(ar) .product-details-text .data h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.product-details-text .data ul {
  padding: 0;
}

.product-details-text .data ul li {
  list-style: disc;
  padding: 10px 0;
  line-height: 1.5;
  font-family: var(--main-font-regular) !important;
  font-size: 18px;
  margin-inline-start: 20px;
}

.product-details-text .data ul li:has(h4) {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-details-text .data ul li:has(p) {
  margin-inline-start: 0;
  list-style: none;
}

.product-details-text .data ul li h4 {
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
  font-family: var(--main-font-bold) !important;
  margin-bottom: 20px;
  font-weight: 900;
}

.w-contain {
  width: auto !important;
  object-fit: contain;
}

.product-details-cards {
  margin-bottom: 40px;
  padding: 50px 50px;
  border-radius: 0 0 10px 10px;
  /* background-image: linear-gradient(#09aad600, #09aad675); */
}

.product-details-cards .product-details-frame {
  height: 100%;
  padding-inline: 5%;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-details-cards .product-details-frame img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: contain;
}

.product-details-cards .data h3 {
  color: #fff;
  font-size: 18px !important;
  font-family: var(--main-font-regular) !important;
  font-weight: normal;
  margin-bottom: 10px;
}

.product-details-cards .data h1 {
  color: #fff;
  font-size: 52px;
  margin-bottom: 30px;
}

.product-details-cards .data h1 {
  text-transform: initial;
  font-family: var(--main-font-bold) !important;
}

.product-details-cards .data ul {
  padding: 0;
}

.product-details-cards .data ul li {
  font-family: var(--main-font-regular) !important;
  font-size: 18px;
  list-style: disc;
  padding: 10px 0px;
  line-height: 1.5;
  margin-inline-start: 20px;
}

.product-details-cards .data ul li:has(h4) {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-details-cards .data ul li h4 {
  font-size: 30px;
  color: #fff;
  font-family: var(--main-font-bold) !important;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 900;
}

.cards-bx {
  display: grid;
  gap: 20px;
  padding-top: 20px;
  grid-template-columns: 1fr 1fr;
}

.cards-bx .card {
  padding: 20px;
  border-radius: 10px;
  background-color: #e8e8ea;
}

.cards-bx .card h3 {
  margin-top: 10px;
}

.cards-bx .card h3,
.cards-bx .card p {
  color: #13294b;
  font-size: 16px;
  line-height: 1.5;
}

:lang(en) .cards-bx .card h3 {
  font-family: var(--main-font-medium);
  font-weight: 700;
}

:lang(en) .cards-bx .card h3,
:lang(en) .cards-bx .card p {
  text-transform: initial;
}
